<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        optionLabel="name"
        placeholder="Selecione um equipamento..."
        :service="$service"
        :itemSize="itemSize"
        :renderValue="(slotProps) => slotProps.value?.name"
        :renderOption="(slotProps) => slotProps.option?.name"
    >
    </AppOnePageDropdown>
</template>
<script>
import SesmtService from '../../../services/SesmtService';
export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new SesmtService('/ferramentas/equipamentos');
    }
};
</script>
<style lang="">
</style>