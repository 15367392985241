<template>
    <div class="grid">
        <div class="col-12">
            <div class="font-medium text-3xl text-900 mb-3">
                <span class="text-primary text-5xl">/</span> Ferramentas <span class="text-primary text-5xl">/</span> Equipamentos
                <span class="text-primary text-5xl">/</span> Agendamentos
            </div>
            <div class="text-500 mb-5">Listagem de agendamentos</div>

            <div class="card">
                <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                    <div class="mt-2 ml-auto md:mt-0">
                        <Button label="Agendar" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openBasic" />
                    </div>
                    <Dialog header="Reserva de equipamento" v-model:visible="displayBasic" :style="{ width: '40vw' }">
                        <div class="grid">
                            <div class="col-12">
                                <div class="col-12">
                                    <div
                                        style="
                                            display: flex;
                                            max-width: 450px;
                                            min-width: 450px;
                                            margin-top: 10px;
                                            align-items: center;
                                            justify-content: space-between;
                                        "
                                    >
                                        <label>Título</label>
                                        <InputText
                                            id="name"
                                            v-model.trim="form.title"
                                            required="true"
                                            autofocus
                                            autocomplete="off"
                                            style="max-width: 320px; min-width: 320px"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 450px; min-width: 450px; align-items: center; margin-top: 10px">
                                        <label style="margin-right: 90px">Período</label>
                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <Calendar
                                                    id="dataInicio"
                                                    v-model="form.periodStart"
                                                    dateFormat="dd/mm/yy"
                                                    selectionMode="single"
                                                    :showIcon="true"
                                                    :showTime="true"
                                                    placeholder="Data Inicio"
                                                    :class="{ 'p-invalid': submitted && !form.periodStart }"
                                                />
                                            </div>
                                            <div class="field col">
                                                <Calendar
                                                    id="dataFim"
                                                    v-model="form.periodEnd"
                                                    dateFormat="dd/mm/yy"
                                                    selectionMode="single"
                                                    :showIcon="true"
                                                    :showTime="true"
                                                    placeholder="Data Fim"
                                                    :class="{ 'p-invalid': submitted && !form.periodEnd }"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style="display: flex; max-width: 450px; min-width: 450px; align-items: center; justify-content: space-between"
                                    >
                                        <label for="name">Profissional</label>
                                        <Dropdown
                                            v-model="form.allProfesionals"
                                            dataKey="id"
                                            style="max-width: 320px; min-width: 320px"
                                            :options="idProfessional"
                                            optionLabel="label"
                                            placeholder="Escolha"
                                            :class="{ 'p-invalid': submitted && !form.allProfesionals }"
                                        />
                                    </div>
                                    <div
                                        style="
                                            display: flex;
                                            max-width: 450px;
                                            min-width: 450px;
                                            margin-top: 10px;
                                            align-items: center;
                                            justify-content: space-between;
                                        "
                                    >
                                        <label for="name">Equipamento</label>
                                        <DropdownEquipamentos
                                            v-model="form.allEquip"
                                            :modelValue="form.allEquip"
                                            style="max-width: 320px; min-width: 320px"
                                            :class="{ 'p-invalid': submitted && !form.allEquip }"
                                        />
                                    </div>
                                </div>
                                <div class="flex mt-5" style="marginbottom: 20px">
                                    <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                                    <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="closeBasic" />
                                </div>
                                <div :style="{ display: this.form?.idScheduled ? 'flex' : 'none' }">
                                    <Button
                                        label="Remover agendamento"
                                        icon="pi pi-times"
                                        class="p-button-primary w-6"
                                        style="backgroundcolor: red; border: none"
                                        @click="deleteScheduled"
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
                <Toast />
                <div class="grid">
                    <div class="col-12">
                        <FullCalendar :options="calendarOptions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { startOfMonth, addMonths, format, subMonths, addDays, startOfWeek, sub, add, eachDayOfInterval, getDay, isToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import pt from '@fullcalendar/core/locales/pt';
import DropdownEquipamentos from '../components/DropdownEquipamentos.vue';
import SecurityService from '../../../services/SecurityService';
import SesmtService from '../../../services/SesmtService';

export default {
    data() {
        return {
            form: {},
            displayBasic: false,
            today: null,

            dayLabels: null,
            isShare: false,
            submitted: false,
            formInfo: undefined,

            allProfesionals: null,
            allEquip: null,

            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
                initialView: 'dayGridMonth',
                locale: pt,
                editable: false,
                selectable: true,
                selectMirror: false,
                dayMaxEvents: false,
                inicialDate: new Date(),
                headerToolbar: {
                    left: 'dayGridMonth,timeGridWeek,timeGridDay',
                    center: 'title',
                    right: 'prev,today,next'
                },
                events: [],
                eventClick: (events) => this.handleDateClick(events?.event?._def?.publicId),
                select: (events) => this.selectDate(events)
            },

            eventId: null,

            currentMonth: startOfMonth(new Date()),
            firstDayOfWeek: startOfWeek(new Date(), {
                weekStartsOn: 1
            }),

            idProfessional: [],
            allProfesionalsExist: [],
            idEquip: []
        };
    },

    components: { FullCalendar, DropdownEquipamentos },

    mounted() {
        this.$service = new SesmtService('/ferramentas/agendamento/equipamentos');
        this.$serviceProfessional = new SecurityService('/ferramentas/profissionais/todos');
        this.$serviceEquipament = new SecurityService('/ferramentas/equipamentos/todos');
        this.load();
    },

    watch: {
        page() {
            this.load();
        }
    },

    methods: {
        // abrir modal de criaçao/ediçao
        openBasic() {
            this.form = {};
            this.displayBasic = true;
        },

        format,
        isToday,

        substractOneMonth() {
            this.currentMonth = sub(this.currentMonth, { months: 1 });
        },
        addOneMonth() {
            this.currentMonth = add(this.currentMonth, { months: 1 });
        },
        resetToToday() {
            this.currentMonth = startOfMonth(new Date());
        },
        selectDate(events) {
            this.form = {
                periodStart: new Date(events?.startStr),
                periodEnd: new Date(events?.endStr)
            };
            this.displayBasic = true;
        },
        formatDate(dateString) {
            const formatDates = format(new Date(dateString), 'yyyy-MM-dd HH:mm');
            return `${formatDates}`;
        },

        closeBasic() {
            this.displayBasic = false;
        },

        async handleDateClick(idScheduled) {
            try {
                const { data } = await this.$service.findById(idScheduled);
                const getOnlyProfessional = this.idProfessional?.find((v) => v?.value === data?.idProfessional?.id);
                this.form = {
                    ...this.form,
                    title: data?.title,
                    periodStart: new Date(data?.periodStart),
                    periodEnd: new Date(data?.periodEnd),
                    allProfesionals: getOnlyProfessional,
                    allEquip: data?.idEquip,
                    idScheduled
                };
                this.displayBasic = true;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar o agendamento selecionado!', life: 3000 });
            }
        },

        async save() {
            if (this.form?.idScheduled) {
                this.saveEdit();
                return;
            }

            try {
                this.submitted = true;
                const body = {
                    title: this.form?.title,
                    periodStart: this.formatDate(this.form.periodStart),
                    periodEnd: this.formatDate(this.form.periodEnd),
                    idEquip: this.form.allEquip.id,
                    idProfessional: this.form.allProfesionals.value
                };
                await this.$service.save(body);
                this.$toast.add({ severity: 'success', summary: 'Agendamento salvo com sucesso!', life: 3000 });
                this.load();
                this.submitted = false;
                this.form = {};
                this.displayBasic = false;
            } catch (error) {
                console.error(error);
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o Agendamento!', life: 3000 });
            }
        },
        async saveEdit() {
            try {
                const formatInversePeriodStart =
                    typeof this.form.periodStart === 'string' ? this.form.periodStart : this.formatDate(this.form.periodStart);
                const formatInversePeriodEnd = typeof this.form.periodEnd === 'string' ? this.form.periodEnd : this.formatDate(this.form.periodEnd);
                this.submitted = true;
                const body = {
                    id: +this.form?.idScheduled,
                    title: this.form?.title,
                    periodStart: formatInversePeriodStart,
                    periodEnd: formatInversePeriodEnd,
                    idEquip: this.form.allEquip.id,
                    idProfessional: this.form.allProfesionals.value
                };
                await this.$service.save(body);
                this.$toast.add({ severity: 'success', summary: 'Agendamento salvo com sucesso!', life: 3000 });
                this.load();
                this.submitted = false;
                this.form = {};
                this.displayBasic = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o Agendamento!', life: 3000 });
            }
        },
        async getScheduled() {
            try {
                const { data } = await this.$service.findAll({});
                const formatAllScheduled = data?.items?.map((v) => {
                    return {
                        id: v?.id,
                        title: v?.title,
                        start: v?.periodStart,
                        end: v?.periodEnd
                    };
                });
                this.calendarOptions = {
                    ...this.calendarOptions,
                    events: formatAllScheduled
                };
            } catch (error) {
                console.error(error);
                this.$toast.add({ severity: 'error', summary: 'Problemas ao lista o agendamento!', life: 3000 });
            }
        },

        async openModalEditEquip(itemSelect) {
            this.form = {
                periodStart: new Date(itemSelect?.periodStart),
                periodEnd: new Date(itemSelect?.periodEnd),
                allEquip: itemSelect?.idEquip,
                allProfesionals: itemSelect?.idProfessional,
                isShare: itemSelect?.isShare
            };
            this.formInfo = itemSelect;
            this.displayBasic = true;
        },

        async deleteScheduled() {
            try {
                // deletar agendamento
                await this.$service.remove(this.form?.idScheduled);
                this.$toast.add({ severity: 'success', summary: 'Agendamento deletado com sucesso!', life: 3000 });
                this.load();
                this.displayBasic = false;
                this.form = {};
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao deletar o Agendamento!', life: 3000 });
            }
        },

        async getProfisionals() {
            try {
                const { data } = await this.$serviceProfessional.findAll(null, {});
                const profisionalsItem = data?.items?.map((v) => {
                    if (v) {
                        return {
                            value: v?.id,
                            label: v?.name
                        };
                    }
                });
                this.idProfessional = profisionalsItem;
                this.allProfesionalsExist = data;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar profissionais', life: 3000 });
            }
        },

        async load() {
            await this.getProfisionals();
            await this.getScheduled();
        }
    },

    computed: {
        previousMonth() {
            return startOfMonth(subMonths(new Date(this.currentMonth), 1));
        },
        nextMonth() {
            return startOfMonth(addMonths(new Date(this.currentMonth), 1));
        },
        daysOfCurrentMonth() {
            return eachDayOfInterval({
                start: this.currentMonth,
                end: sub(this.nextMonth, { days: 1 })
            });
        },
        weekNames() {
            return [...Array(7)].map((_, index) => format(addDays(this.firstDayOfWeek, index), 'EEEEE', { locale: ptBR }));
        },
        weekdayOffset() {
            return (getDay(this.currentMonth) + 7) % 7 || 7; // `|| 7` is basically for sunday, edge case
        }
    }
};
</script>

<style scoped lang="scss">
.days,
.week-days {
    display: grid;
    grid-template-columns: repeat(7, 50px);
    grid-column: 7;
}
</style>
